<template>
  <div class="add-amenity">
    <h1 class="title">{{ `${$t('New Amenity')}` }}</h1>
    <b-row>
      <b-col cols="12" lg="8">
        <ValidationObserver ref="validator">
          <form @submit.prevent>
            <RFValidation rules="required" name="Name">
              <RFInput label="Name" v-model="amenity.name" :value="amenity.name" :readonly="loading"/>
            </RFValidation>

            <RFValidation rules="required" name="Translations">
              <RFInputTranslations label="Translations" v-model="amenity.translations" :value="amenity.translations"
                                   @change="changeTranslations" :readonly="loading"/>
            </RFValidation>

            <RFValidation rules="required" name="Default Value">
              <RFSelect top-label="Default Value" v-model="amenity.defaultValue" :value="amenity.defaultValue"
                        :list="defaultValueList"/>
            </RFValidation>
          </form>
        </ValidationObserver>
      </b-col>
      <b-col cols="12" lg="4">
        <div class="amenity-image my-4 my-lg-1">
          <div class="image" v-if="file" :style="{ backgroundImage: `url(${getPreview(file)})`, backgroundColor: '#DADBD7', backgroundSize: 'contain' }"/>
          <!--          <div class="image" :style="{ backgroundImage: `url(${imagePath})`}"/>-->
          <RFFile class="my-1" size="small" label="Add Image" background="#fff" color="#5CB86C" border-color="#5CB86C"
                  v-if="!file" @file="uploadFile($event)"/>
          <div class="hover" v-if="file">
            <FontAwesomeIcon class="delete-icon" color="#fff" size="lg" :icon="['fas', 'trash']"
                             @click="deleteAmenityImage"/>
          </div>
        </div>
        <transition name="fade">
          <div class="vee-error" v-if="showImageError">{{imageError}}</div>
        </transition>
      </b-col>
    </b-row>

    <b-row class="justify-content-end">
      <b-col cols="auto">
        <RFButton class="mt-4" label="Save" background="#5CB85C" v-if="!loading" @on-click="save"/>
        <RFLoader :size="30" color="#24425B" v-else/>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import RFFile from '@/components/forms/RFFile'
import RFInput from '@/components/forms/RFInput'
import RFInputTranslations from '@/components/forms/RFInputTranslations'
import RFSelect from '@/components/forms/RFSelect'
import RFButton from '@/components/forms/RFButton'
import RFLoader from '@/components/forms/RFLoader'
import RFValidation from '@/components/forms/RFValidation'
import {amenityService} from '@/services/admin'

export default {
  name: 'AdminAddAmenity',
  components: {
    RFFile,
    RFInput,
    RFInputTranslations,
    RFButton,
    RFSelect,
    RFLoader,
    RFValidation,
  },
  data() {
    return {
      amenity: {
        name: null,
        defaultValue: null,
        translations: null,
      },
      file: null,
      imagePath: null,
      loading: false,
      defaultValueList: [],
      showImageError: false,
      imageError: 'Image is required. Valid formats are png, jpeg and svg.',
      validTypes: ['image/png', 'image/jpeg', 'image/svg+xml']
    }
  },
  methods: {
    changeTranslations(translations) {
      this.amenity.translations = translations
      this.defaultValueList = Object.values(this.amenity.translations).map((value) => {
        return value ? {label: value, value} : {}
      })
      this.defaultValueList = this.defaultValueList.filter(defaultValue => Object.entries(defaultValue).length !== 0)
      if(this.defaultValueList.length===0){
        this.amenity.defaultValue = null
      }

    },
    getPreview(file) {
      this.imagePath = URL.createObjectURL(file)
      return file ? URL.createObjectURL(file) : null
    },
    deleteAmenityImage() {
      this.imagePath = null
      this.file = null
    },
    async save() {

      const isValid = await this.$refs.validator.validate()
      if (!this.file || (this. file && !this.validTypes.includes(this.file.type))) {
        this.showImageError = true;
      } else if (isValid) {
        this.loading = true

        const form = new FormData()
        form.append('amenity', JSON.stringify(this.amenity))
        form.append('file', this.file)

        const {status} = await amenityService.addAmenity(form)

        if (status === 200) {
          this.$toasted.success(this.$i18n.t(`Amenity correctly added!`))
          this.$router.push({name: 'amenity-list'})
        }

        this.loading = false
      }
    },
    uploadFile(event){
      this.file = event
      this.showImageError = false
    }
  }
}
</script>

<style lang="scss" scoped>
.add-amenity {
  .title {
    @include font-style($montserrat, 'semibold', $font-30);
    color: $dark-blue;
    text-transform: uppercase;
  }

  .amenity-label {
    @include font-style($montserrat-bold, 'bold', $font-14);
    color: $dark-blue;
    text-align: center;
    text-transform: uppercase;
  }

  .amenity-data {
    @include font-style($hind, 'medium', $font-14);
    color: $dark-blue;
    text-align: center;
  }

  .amenity-image {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 250px;
    width: 100%;
    max-height: 250px;
    border: 1px solid $gray;
    cursor: pointer;

    &:hover {
      .hover {
        opacity: 1;
      }
    }

    .hover {
      position: absolute;
      background-color: rgba($black, .4);
      width: 100%;
      height: 100%;
      z-index: 30;
      top: 0;
      left: 0;
      opacity: 0;
      transition: opacity .3s;

      .delete-icon {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }

    .image {
      width: 100%;
      height: 100%;
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
    }

    .trash-icon {
      position: absolute;
      bottom: -5px;
      right: -5px;
    }
  }
}
</style>
